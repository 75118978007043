<template>
  <addEditForm handType="add"></addEditForm>
</template>

<script>
import addEditForm from './form.vue';

export default {
  name: 'CommunityAMapAdd',
  components: {
    addEditForm
  },
  props: {},
  model: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style lang="less" scoped></style>
