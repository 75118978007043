// 查询 - 设施/服务 类别 - 字典表
const getServiceCategoryDictUrl = `/gateway/hc-serve/manage/publicFacilitiesInfo/getFacilitiesServiceType`;

// 查询 - 活动场地名称 - 列表
const getHdcdmcListUrl = `/gateway/hc-serve/manageapi/activity/reservationPlaceList`;

// 查询 - 预约场地名称 - 列表
const getYycdmcListUrl = `/gateway/hc-serve/miniapi/reservation/placeList`;

// 查询 - 社区一张图 - 列表
const getSqyztListUrl = `/gateway/hc-serve/manage/publicFacilitiesInfo/getPublicFacilitiesInfoList`;

// 查询 - 社区一张图 - 详情
const getSqyztDetailUrl = `/gateway/hc-serve/manage/publicFacilitiesInfo/getDetailsById`;

// 保存 - 社区一张图
const saveSqyztInfoUrl = `/gateway/hc-serve/manage/publicFacilitiesInfo/saveOrUpdate`;

// 删除 - 社区一张图
const deleteSqyztInfoUrl = `/gateway/hc-serve/manage/publicFacilitiesInfo/delById`;

export {
  getServiceCategoryDictUrl,
  getHdcdmcListUrl,
  getYycdmcListUrl,
  getSqyztListUrl,
  getSqyztDetailUrl,
  saveSqyztInfoUrl,
  deleteSqyztInfoUrl
};
