<template>
  <div class="add-edit-form">
    <form-panel
      ref="form_panel"
      v-bind="submitConfig"
      :form="form"
      labelWidth="160px"
      :disabled="formDisabled"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="公共设施名称"
        prop="facilitiesName"
        :rules="[{ required: true, message: '请输入公共设施名称', trigger: 'blur' }]"
      >
        <v-input v-model="form.facilitiesName" placeholder="请输入公共设施名称" clearable :width="400" />
      </el-form-item>
      <el-form-item label="设施类别" prop="facilitiesType" :rules="[{ required: true, message: '请选择设施类别', trigger: 'change' }]">
        <v-select v-model="form.facilitiesType" placeholder="请选择设施类别" clearable :options="facilitiesCategoryOptions" :width="400" />
      </el-form-item>
      <el-form-item label="服务类别" prop="serviceType" :rules="[{ required: true, message: '请选择服务类别', trigger: 'change' }]">
        <v-select v-model="form.serviceType" placeholder="请选择服务类别" clearable :options="serviceCategoryOptions" :width="400" />
      </el-form-item>
      <el-form-item
        label="经纬度"
        prop="longitude"
        :rules="[
          { required: true, message: '请输入经度', trigger: 'change' },
          { type: 'number', message: '请输入数值', trigger: 'blur' }
        ]"
      >
        <v-input v-model.number="form.longitude" type="number" placeholder="请输入经度" clearable :width="240" />
        <el-form-item
          prop="latitude"
          :rules="[
            { required: true, message: '请输入纬度', trigger: 'change' },
            { type: 'number', message: '请输入数值', trigger: 'blur' }
          ]"
          style="margin: 0px 20px"
        >
          <v-input v-model.number="form.latitude" type="number" placeholder="请输入纬度" clearable :width="240" />
        </el-form-item>
        <v-button type="primary" text="定位" @click="mapDialogShow = true" />
      </el-form-item>
      <el-form-item
        label="详细地址"
        prop="address"
        :rules="[{ required: true, message: '请输入详细地址', trigger: ['change', 'blur'] }]"
      >
        <v-input v-model="form.address" placeholder="请输入详细地址" clearable :width="400" />
      </el-form-item>
      <el-form-item label="热点图片" prop="hotImages">
        <v-upload :limit="1" :imgUrls.sync="form.hotImages" tips="注：不上传使用默认图标" />
      </el-form-item>
      <el-form-item
        label="设施图片"
        prop="facilitiesImages"
        :rules="[{ required: true, message: '请上传设施图片', trigger: ['change', 'blur'] }]"
      >
        <v-upload :limit="1" :imgUrls.sync="form.facilitiesImages" />
      </el-form-item>
      <el-form-item
        label="是否为活动场地"
        prop="isUseActivity"
        :rules="[{ required: true, message: '请选择是否为活动场地', trigger: 'change' }]"
      >
        <v-radio v-model="form.isUseActivity" :radioObj="whetherOptions" />
        <el-form-item
          label="对应活动场地名称"
          prop="hdcdName"
          :rules="[{ required: form.isUseActivity === 1, message: '请选择对应活动场地名称', trigger: 'change' }]"
          style="width: 100%"
        >
          <v-select
            v-model="form.hdcdName"
            placeholder="请选择对应活动场地名称"
            clearable
            multiple
            collapse-tags
            :options="hdcdOptions"
            :width="400"
            :disabled="form.isUseActivity !== 1"
          />
        </el-form-item>
      </el-form-item>
      <el-form-item
        label="是否为可预约空间"
        prop="isUseSpace"
        :rules="[{ required: true, message: '请选择是否为可预约空间', trigger: 'change' }]"
      >
        <v-radio v-model="form.isUseSpace" :radioObj="whetherOptions" />
        <el-form-item
          label="对应预约场地名称"
          prop="yycdName"
          :rules="[{ required: form.isUseSpace === 1, message: '请选择对应预约场地名称', trigger: 'change' }]"
          style="width: 100%"
        >
          <v-select
            v-model="form.yycdName"
            placeholder="请选择对应预约场地名称"
            clearable
            multiple
            collapse-tags
            :options="yycdOptions"
            :width="400"
            :disabled="form.isUseSpace !== 1"
          />
        </el-form-item>
      </el-form-item>
    </form-panel>

    <v-map :selectedLng="+form.longitude" :selectedLat="+form.latitude" :showMapDialog.sync="mapDialogShow" @setLngAndLat="setLngAndLat" />
  </div>
</template>

<script>
import { setAESCbcEncrypt } from '@/utils/common';
import { mobileRegular } from '@/utils/regular';
import { getServiceCategoryDictUrl, getHdcdmcListUrl, getYycdmcListUrl, getSqyztDetailUrl, saveSqyztInfoUrl } from './api.js';
import { handTypeMap, whether } from './map.js';

export default {
  name: 'CommunityAMapForm',
  props: {
    handType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      facilitiesCategoryOptions: [],
      serviceCategoryOptions: [],
      whetherOptions: whether.map(item => ({ value: item.label, name: item.value })),
      hdcdOptions: [],
      yycdOptions: [],
      submitConfig: {
        queryUrl: getSqyztDetailUrl,
        submitUrl: saveSqyztInfoUrl
      },
      form: {
        id: '',
        facilitiesName: '',
        facilitiesType: '',
        serviceType: '',
        longitude: '',
        latitude: '',
        address: '',
        hotImages: '',
        facilitiesImages: '',
        isUseActivity: '',
        activitySpace: '',
        hdcdName: [],
        isUseSpace: '',
        reservationSpace: '',
        yycdName: [],
        source: 1, // 1:自己新增  2:省平台同步
        tenantId: ''
      },
      mapDialogShow: false
    };
  },
  computed: {
    formDisabled() {
      return ['view', 'look', 'detail'].includes(this.handType);
    }
  },
  watch: {
    'form.hdcdName': {
      handler(value) {
        this.form.activitySpace = value.join(',');
      },
      deep: true
    },
    'form.yycdName': {
      handler(value) {
        this.form.reservationSpace = value.join(',');
      },
      deep: true
    }
  },
  components: {},
  created() {
    this.form.tenantId = this.$store.state.app.userInfo.tenantId;
    Promise.all([
      this.$axios.get(getServiceCategoryDictUrl),
      this.$axios.get(getHdcdmcListUrl),
      this.$axios.get(getYycdmcListUrl, { params: { curPage: 1, pageSize: 99 } })
    ])
      .then(dictRes => {
        const [ssfuDict, hdcdList, yycdList] = dictRes;

        ssfuDict.data = ssfuDict.data || {};
        ssfuDict.data[1] = Array.isArray(ssfuDict.data[1]) ? ssfuDict.data[1] : [];
        ssfuDict.data[2] = Array.isArray(ssfuDict.data[2]) ? ssfuDict.data[2] : [];
        this.facilitiesCategoryOptions = ssfuDict.data[1].map(item => ({ label: item.typeName, value: item.typeCode }));
        this.serviceCategoryOptions = ssfuDict.data[2].map(item => ({ label: item.typeName, value: item.typeCode }));

        hdcdList.data = Array.isArray(hdcdList.data) ? hdcdList.data : [];
        this.hdcdOptions = hdcdList.data.map(item => ({ label: item.placeName, value: item.placeId }));

        yycdList.data = yycdList.data || {};
        yycdList.data.records = Array.isArray(yycdList.data.records) ? yycdList.data.records : [];
        this.yycdOptions = yycdList.data.records.map(item => ({ label: item.name, value: item.id }));
      })
      .finally(() => {});
  },
  mounted() {
    const { id } = this.$route.query;
    if (['edit', 'modify', 'view', 'look', 'detail'].includes(this.handType)) {
      this.form.id = id;
      this.$refs.form_panel.getData({ id });
    }
    this.$setBreadList(handTypeMap[this.handType] || '');
  },
  methods: {
    setLngAndLat(value) {
      if (value.lng && value.lat) {
        this.form.longitude = value.lng;
        this.form.latitude = value.lat;
      }
      this.form.address = value.address;
    },
    update(data) {
      Object.keys(this.form).forEach(key => {
        if (!['hdcdName', 'yycdName', 'activitySpace', 'activitySpace'].includes(key)) {
          this.form[key] = data[key];
        }
        this.form.hdcdName = data.activitySpace.split(',').filter(item => !!item);
        this.form.yycdName = data.reservationSpace.split(',').filter(item => !!item);
      });
    },
    submitBefore() {
      return true;
    },
    submitSuccess() {
      return true;
    }
    // validatePhone(rule, value, callback) {
    // },
  }
};
</script>

<style lang="less" scoped>
.add-edit-form {
  box-sizing: border-box;
  height: 100%;
  .form-item {
    display: flex;
  }
}

/deep/ .el-form-item__content {
  flex-wrap: wrap;
}
/deep/ input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;
}
/deep/ input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
