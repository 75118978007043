import { mapHelper } from '@/utils/common.js';

// 是否
const whether = [
  { label: '是', value: 1 },
  { label: '否', value: 0 }
];
const { map: whetherMap, setOps: setWhetherOps } = mapHelper.setMap(whether);

// 设施类别
const facilitiesCategory = [
  { label: '治理服务', value: '1' },
  { label: '教育服务', value: '2' },
  { label: '养老服务', value: '3' },
  { label: '卫生服务', value: '4' },
  { label: '文化服务', value: '5' },
  { label: '商业(食品)服务', value: '6' },
  { label: '体育服务', value: '7' },
  { label: '便民服务', value: '8' }
];
const { map: facilitiesCategoryMap, setOps: setFacilitiesCategoryOps } = mapHelper.setMap(facilitiesCategory);

// 服务类别
const serviceCategory = [
  { label: '营利性', value: '1' },
  { label: '社区综合服务中心/党群服务中心', value: '2' },
  { label: '非营利性', value: '3' },
  { label: '小学', value: '4' },
  { label: '幼儿园', value: '5' },
  { label: '托育机构/社区照护驿站', value: '6' },
  { label: '社区居家养老照料中心', value: '7' },
  { label: '社区老年食堂', value: '8' },
  { label: '养老机构', value: '9' },
  { label: '社区卫生服务中心', value: '10' },
  { label: '社区卫生服务站&智慧健康站', value: '11' },
  { label: '社区文化家园/文化学堂', value: '12' },
  { label: '四点半学堂/幸福学堂', value: '13' },
  { label: '公共文化设施/城市书房/共享书屋', value: '14' },
  { label: '综合文化站', value: '15' },
  { label: '社区文化活动室', value: '16' },
  { label: '综合菜市场', value: '17' },
  { label: '体育馆', value: '18' },
  { label: '健身步道', value: '19' },
  { label: '百姓健身房', value: '20' },
  { label: '健身路径', value: '21' },
  { label: '体育公园', value: '22' }
];
const { map: serviceCategoryMap, setOps: setServiceCategoryOps } = mapHelper.setMap(serviceCategory);

// 操作类型
const handType = [
  { label: '新增', value: 'add' },
  { label: '编辑', value: 'edit' },
  { label: '修改', value: 'modify' },
  { label: '详情', value: 'view' },
  { label: '查看', value: 'look' },
  { label: '详情', value: 'detail' }
];
const { map: handTypeMap, setOps: setHandTypeOps } = mapHelper.setMap(handType);

export {
  whether,
  whetherMap,
  setWhetherOps,
  facilitiesCategory,
  facilitiesCategoryMap,
  setFacilitiesCategoryOps,
  serviceCategory,
  serviceCategoryMap,
  setServiceCategoryOps,
  handType,
  handTypeMap,
  setHandTypeOps
};
